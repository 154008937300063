<template>
  <div>
    <TabsTickets />
  </div>
</template>
<script>
import TabsTickets from '@/components/migraciones/tecnicoMigraciones/TabsMigraciones.vue'

export default {
  components: {
    TabsTickets,
  },
}
</script>
